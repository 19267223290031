import React, { useRef, useEffect } from "react"
import { Image, OverlayTrigger, Tooltip } from "react-bootstrap"
import { useStoreActions, useStoreState } from "easy-peasy"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import { useMatch, useLocation } from "@reach/router"
import { parse } from "query-string"
import Logo from "../../img/logo-diap.png"
import PartnerLogo from "../partner/PartnerLogo"
import Footer from "./Footer"
import { connectorLogoURL } from "../../helpers/assets"
import { path, redirect } from "../../config/routes"
import { connectorsByCategory, connectorsRanked } from "../../helpers/connector"
import { CONNECTOR_STATUSES } from "../../store/models/connectors"

const ConnectorCard = ({ connector, isPartnerPage }) => {
  const target = useRef(null)
  return (
    <>
      <OverlayTrigger
        overlay={props => (
          <Tooltip {...props}>
            <div className="d-flex align-items-center">
              <h3 className="mb-0">{connector.name}</h3>
              {connector.status === CONNECTOR_STATUSES.beta && (
                <p className="mb-0 ml-2 bg-gray-900 rounded px-2 py-1 text-nowrap">
                  <FormattedMessage
                    id="components.layout.signInLayout.comingSoon"
                    defaultMessage="Coming soon"
                  />
                </p>
              )}
            </div>
          </Tooltip>
        )}
      >
        <Link
          ref={target}
          to={`${path(isPartnerPage ? "PARTNER_ABOUT_YOUR_TEAM" : "SIGN_UP")}?connector=${connector.id}`}
          className="btn m-3 p-3 rounded bg-gray-0"
        >
          <Image
            className="connector-card--image border-0"
            src={(connector.logo) ? connector?.logo?.file?.url : connectorLogoURL()}
            onError={e => {
              if (e.target.src !== connectorLogoURL())
                e.target.src = connectorLogoURL()
            }}
          />
        </Link>
      </OverlayTrigger>
    </>
  )
}

const SignInLayout = ({ children, vendorDetails, connectorData }) => {
  const signUpMatch = useMatch(`/:locale${path("SIGN_UP")}/*`)
  const signInMatch = useMatch(`/:locale${path("SIGN_IN")}/*`)
  const partnerAboutYourTeamMatch = useMatch(`/${path("PARTNER_ABOUT_YOUR_TEAM")}/*`)
  const rootMatch = useMatch(`/:locale?connector=:connector`)
  const prettyLinkMatch = useMatch(`/:locale/connect/:connector`)
  const location = useLocation();
  const connectorId = parse(location.search).connector
  const setConnectors = useStoreActions(actions => actions.connectors.setPublic)
  const connectors = useStoreState(state => state.connectors.public)
  const brandName = vendorDetails?.name

  const isLandingRoute = [signUpMatch, signInMatch, rootMatch, prettyLinkMatch, partnerAboutYourTeamMatch].find(match => match)
  const connector = connectors.find(({ id }) => ([isLandingRoute?.connector, connectorId].includes(id)))

  const connectorsByRank = connectorsRanked(
    connectorsByCategory(connectors)
  ).reduce((acc, [_, connector]) => [...acc, ...connector], [])

  useEffect(() => {
    if (connectors.length === 0)
    setConnectors(connectorData)
  }, [connectors])

  const renderConnectors = ({ isPartnerPage }) => (
    <div className="sign-up-message m-auto max-w-4xl">
      <h1 className="font-weight-bold text-white mt-6 mb-4 max-w-3xl">
        <FormattedMessage
          id="components.layout.signInLayout.lspHeading"
          defaultMessage="Connect the tools you already use with {brandName}"
          values={{ brandName }}
        />
      </h1>
      <div className="d-flex flex-wrap m-n2" style={{ minHeight: "500px" }}>
        {connectorsByRank.map(c => (
          <ConnectorCard isPartnerPage={isPartnerPage} connector={c} key={c.id}/>
        ))}
      </div>
    </div>
  )

  const renderConnectorDetail = ({ id, name, logo }, isPartnerPage = false) => (
    <div className="sign-up-message ml-6 d-flex flex-column w-75">
      <div className="bg-white rounded mb-3 p-3 align-self-start">
        <Image
          className="connector-card--image"
          src={(logo) ? logo : connectorLogoURL()}
        />
      </div>
      <h1>
        <FormattedMessage
          id="components.layout.signInLayout.lspHeading.detail"
          defaultMessage="Connect <b>{connector}</b> with {brandName}"
          values={{
            brandName,
            connector: name,
            b: msg => <b className="text-capitalize">{msg}</b>,
          }}
        />
      </h1>
      <h2 className="sign-up-message__desc text-white">
        <FormattedMessage
          id="components.layout.signInLayout.lspHeading.detail.p"
          defaultMessage="Use Locale to push new content from <b>{connector}</b> to {brandName}. 1-minute setup."
          values={{
            brandName,
            connector: name,
            b: msg => <b className="text-capitalize">{msg}</b>,
          }}
        />
      </h2>
      <Link
        to={isPartnerPage ? path("PARTNER_ABOUT_YOUR_TEAM") : path("SIGN_UP")}
        className="btn bg-gray-800 align-self-start text-white mt-3 py-2 px-3"
      >
        <FormattedMessage
          id="components.layout.signInLayout.viewAll"
          defaultMessage="View all connectors"
        />
      </Link>
    </div>
  )

  const renderLandingBody = () => {
    if (connectors.length === 0) return
    if (isLandingRoute && (isLandingRoute.connector || connectorId) && !connector) return redirect("SIGN_UP")
    if (isLandingRoute && (isLandingRoute.connector || connectorId) && connector) return renderConnectorDetail(connector, partnerAboutYourTeamMatch)
    else return renderConnectors({ isPartnerPage: partnerAboutYourTeamMatch })
  }

  return (
    <>
      <div className="sign-up">
        <div className="sign-up-intro bg-gray-800-to-900">
          <div role="presentation" className="sign-up-intro__fade"></div>
          <div className="d-flex flex-column justify-content-between">
            <img src={Logo} className="sign-up__logo" />
            {renderLandingBody()}
            <Footer className="mt-0" />
          </div>
        </div>
        <div className="sign-up-form">
          <h2 className="sign-up__partner-logo d-flex align-items-center m-0">
            <PartnerLogo
              vendorDetails={vendorDetails}
              fromUrl
              logoClassName="sign-up__partner-img bg-white"
            />
          </h2>
          <div className="sign-up-form__authenticator">{children}</div>
        </div>
      </div>
    </>
  )
}

export default SignInLayout
