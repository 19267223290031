import React from "react"
import { useLocation } from "@reach/router"
import { useStoreState } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import { ORG_TYPES } from "../../models/organization"
import { getWorkspaceTypeFromPath } from "../../helpers/user"

const PartnerLogo = ({ fromUrl, logoClassName, vendorDetails, poweredBy }) => {
  const imageUrl = vendorDetails?.logo?.file?.url
  const vendorName = vendorDetails?.name
  const location = useLocation()

  const workspaceType = useStoreState(state => state.currentOrg.workspaceType)

  const type = fromUrl
    ? getWorkspaceTypeFromPath(location.hostname)
    : workspaceType

  const logo = () => {
    switch (type) {
      case ORG_TYPES.rws:
        return (
          <span className="d-flex align-items-center">
            <img
              src={imageUrl}
              alt="RWS"
              className={`mr-3 ${logoClassName} brand__logo`}
            />
          </span>
        )
      case ORG_TYPES.textmaster:
        return (
          <span className="d-flex align-items-center">
            <img
              src={imageUrl}
              alt="Textmaster"
              className={`mr-3 ${logoClassName} brand__logo`}
            />
          </span>
        )
      default:
        return (
          <span className="d-flex align-items-center">
            {imageUrl ? (
              <img
                src={imageUrl}
                alt={vendorName}
                className={`mr-3 ${logoClassName} brand__logo`}
              />
            ) : (
              vendorName
            )}
          </span>
        )
    }
  }

  return (
    <div className="d-flex flex-column">
      <div className="mb-1">{logo()}</div>
      {poweredBy && (
        <span className="text-gray-500 font-weight-bold text-xs">
          <FormattedMessage
            id="components.layout.admin.sidebar.brand.poweredBy"
            defaultMessage="Powered by Locale"
          />
        </span>
      )}
    </div>
  )
}

export default PartnerLogo
