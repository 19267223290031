import { path } from "../config/routes"
import { Auth } from "aws-amplify"

export const refreshToken = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession()

    await new Promise((resolve, reject) =>
      cognitoUser.refreshSession(currentSession.refreshToken, err => {
        if (err) reject(err)
        else resolve()
      })
    )
  } catch (error) {
    console.error(error)
  }
}

export const isConnectPath = currentPath => {
  return new RegExp(path("CONNECT")).test(currentPath)
}

export const isSignInPath = currentPath => {
  return new RegExp(path("SIGN_IN")).test(currentPath)
}

export const isSignUpPath = currentPath => {
  return new RegExp(path("SIGN_UP")).test(currentPath)
}
