import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { useIntl } from "gatsby-plugin-intl"
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from "@aws-amplify/ui-react"
import { AuthState } from '@aws-amplify/ui-components';
import Amplify, { I18n, Auth } from "aws-amplify"
import AmplifyI18n from "amplify-i18n"
import { isSignInPath } from "../../helpers/authentication"
import awsconfig from "../../aws-exports"
import { getWorkspaceTypeFromPath } from "../../helpers/user";
import { ORG_TYPES } from "../../models/organization"
import HybridStorage from "../../config/hybridStorage"
import { parse } from "query-string"

Amplify.configure({
  ...awsconfig,
  storage: new HybridStorage({
    domain: process.env.NEPAL_DOMAIN,
    expires: 7,
    path: "/",
    sameSite: "lax",
    secure: process.env.NODE_ENV === "production",
  }),
});
AmplifyI18n.configure()

const Authenticator = ({ children, state }) => {
  const location = useLocation()
  const currentPath = location.pathname
  const intl = useIntl()
  const queryParameters = parse(location.search);
  I18n.setLanguage(intl.locale)

  const isSignupEnabled = [`textmaster.${process.env.NEPAL_DOMAIN}`, `alpha.${process.env.NEPAL_DOMAIN}`].includes(location.hostname)

  const getInitialAuthState = () => {
    if (!isSignupEnabled) return AuthState.SignIn
    return isSignInPath(currentPath) ? AuthState.SignIn : AuthState.SignUp
  }

  const [authState, setAuthState] = useState(AuthState.SignIn)
  const [refresh, setRefresh] = useState(true)

  const handleSignUp = async formData => {
    const workspaceType = getWorkspaceTypeFromPath(location.hostname)

    const { checkbox, ...attributes } = formData.attributes

    const data = await Auth.signUp({
      ...formData,
      attributes: {
        ...attributes,
        "custom:type": state?.account_type === "lsp" ? "vendor" : undefined,
      },
      clientMetadata: {
        type: workspaceType !== "partner" ? workspaceType : ORG_TYPES.locale,
        accountType: state?.account_type,
        partner: state?.partner,
        partnerID: state?.partner_id,
      },
    })

    return data
  }

  var timeout

  useEffect(() => {
    setAuthState(getInitialAuthState())
    timeout = setTimeout(() => setRefresh(false), 1000);
    return () => { if (timeout) clearTimeout(timeout) };
  }, [])

  useEffect(() => {
    if(getWorkspaceTypeFromPath(location.hostname) !== ORG_TYPES.locale) {
      const authenticator = document.querySelector("#amplify-authenticator")
      const signIn = document.querySelector("#amplify-sign-in")
      const signUp = document.querySelector("#amplify-sign-up")

      authenticator.style = "--width: 100%;"
      signIn.style = "width: 100%;"
      signUp.style = "width: 100%;"
    }
  }, [])

  useEffect(() => {
    if (authState === AuthState.SignUp) {
      const style = document.createElement("style")
      style.innerHTML = `
      .auth-fields > amplify-form-field .form-field {
        display: flex;
        flex-direction: row-reverse;
        justify-content: start;
        align-items: center;
      }
    `

      const amplifySignUp = document.querySelector("#amplify-sign-up")

      if (!amplifySignUp || !amplifySignUp.shadowRoot) return

      amplifySignUp.shadowRoot.appendChild(style)

      const checkbox = amplifySignUp.shadowRoot.getElementById("terms-conditions-checkbox")

      const formField = checkbox?.closest(".form-field")

      if (!formField || !checkbox) return

      checkbox.style = "margin: 0; width: 1rem;";

      if (formField.querySelector("#terms-conditions-link")) return
      if (formField.querySelector("#terms-conditions-button")) return

      const button = document.createElement("button")
      button.id = "terms-conditions-button"
      button.innerHTML = intl.formatMessage({
        id: "components.auth.authenticator.button.label",
        defaultMessage: "I agree with the ",
      })
      button.style =
        "background: none; outline: none; border: none; margin: 0 0.25rem 0 0.6rem; padding: 0;"
      button.addEventListener("click", event => {
        event.preventDefault()
        checkbox.checked = !checkbox.checked
      })

      const a = document.createElement("a")
      a.id = "terms-conditions-link"
      a.target = "_blank"
      a.href = process.env.TERMS_AND_CONDITIONS_URL
      a.innerHTML = "Terms & Conditions"
      a.style =
        "padding: 0; color: var(--amplify-primary-color);"

      formField.insertBefore(button, formField.childNodes[0])
      formField.insertBefore(a, formField.childNodes[0])
    }
  }, [authState, refresh])

  return (
    <AmplifyAuthenticator
      id="amplify-authenticator"
      usernameAlias="email"
      initialAuthState={getInitialAuthState()}
      handleAuthStateChange={auth => setAuthState(auth)}
    >
      <AmplifySignIn
        id="amplify-sign-in"
        slot="sign-in"
        hideSignUp={!isSignupEnabled}
        formFields={[
          {
            type: "email",
            required: true,
          },
          {
            type: "password",
            required: true,
          },
        ]}
      />
      <AmplifySignUp
        id="amplify-sign-up"
        slot="sign-up"
        usernameAlias="email"
        handleSignUp={handleSignUp}
        formFields={[
          {
            type: "email",
            required: true,
          },
          {
            type: "password",
            required: true,
          },
          {
            type: "checkbox",
            fieldId: "terms-conditions-checkbox",
            inputProps: {
              id: "terms-conditions-checkbox",
              style: {
                width: "1rem",
                marginRight: "1rem",
              },
            },
            required: true,
          },
        ]}
      />
      {children}
    </AmplifyAuthenticator>
  )
}

export default Authenticator